import React, { forwardRef } from "react";
import { Card, CardContent, Button, Typography, CardActions, Divider } from "@mui/material";
import { ClickAwayListener } from "@mui/material";

const Photo = forwardRef((props, ref) => {
  return (
    <ClickAwayListener onClickAway={() => props.handleTransitionImageCard(false)}>
      <Card sx={{ maxHeight: "92%", maxWidth: "80%", position: "fixed", zIndex: "1", top: "5%"}} elevation={4} ref={ref} onContextMenu={(e) => e.preventDefault()} onDragStart={(e) => e.preventDefault()}>
        <CardContent sx={{objectFit: "contain"}}>
          <img src={props.selectedImage} alt="" style={{objectFit: "contain", maxWidth: "100%", maxHeight: "80vh"}}/>
          <Divider variant="left"/>
        </CardContent>
        <CardActions sx={{  display: "flex", justifyContent: "center", marginTop: "-15px" }}>
          <Button onClick={() => props.handleTransitionImageCard(false)}>
            <Typography variant="h6">
              X
            </Typography>
          </Button>
        </CardActions>
      </Card>
    </ClickAwayListener>
  );
});

export default Photo;