import { React, useEffect, useState, useRef } from "react"
import { ImageList, ImageListItem, Grow, Slide, CircularProgress, Typography, Fade } from "@mui/material"
import { TransitionGroup } from "react-transition-group"
import styles from "./PhotoViewer.module.css"
import Photo from "@components/Photo/Photo"

const PhotoViewer = (props) => {
  const mountRef = useRef(false)
  const [transitionImageCard, setTransitionImageCard] = useState(false)
  const [currentEnlargedImage, setCurrentEnlargedImage] = useState(null)
  const [isVisible, setIsVisible] = useState(`${styles["hidden"]}`)

  useEffect(() => {
    if (mountRef.current === false) {
      mountRef.current = true
    }
    else {
      if (transitionImageCard) {
        setIsVisible(`${styles.dim}`)
      }
      else {
        setIsVisible(`${styles.dim} ${styles["hidden-transition"]}`)
      }
    }
  }, [isVisible, transitionImageCard])

  const handleCurrentEnlargedImage = (imageUrl) => {
    setCurrentEnlargedImage(imageUrl)
    setTransitionImageCard(true)
  }

  const updateImageLoaded = (imageIndex) => {
    props.setPhotoBucket(prevState => {
      let newData = [...prevState]
      newData.splice(imageIndex, 1, { ...prevState[imageIndex], loaded: true })
      return newData
    })
  }

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div className={isVisible} onClick={() => false}></div>
      <Slide direction="up" in={transitionImageCard} mountOnEnter unmountOnExit >
        <Photo handleTransitionImageCard={setTransitionImageCard} selectedImage={currentEnlargedImage} />
      </Slide>
      {props.photoBucket.length > 0 ?
        <ImageList variant="masonry" cols={3} gap={8} sx={{ padding: "7px" }}>
          <TransitionGroup>
            {props.photoBucket.map((item, index) => (
              <Grow in={props.photoBucket[index].loaded} key={item.original}>
                <ImageListItem key={item.original} sx={{ minWidth: "200px", height: "auto", }} onContextMenu={(e) => e.preventDefault()} onDragStart={(e) => e.preventDefault()}>
                  <img
                    key={item.original}
                    loading="lazy"
                    width="1rem"
                    height="1rem"
                    onLoad={() => updateImageLoaded(index)}
                    onError={() => console.log("ERROR loading photo number ", index)}
                    className={styles.photo}
                    src={item.original}
                    alt=""
                    onClick={() => handleCurrentEnlargedImage(item.original)} />
                </ImageListItem>
              </Grow>
            ))}
          </TransitionGroup>
        </ImageList>
        :
        <div className={styles.loading}>
          <br />
          <CircularProgress size={"5rem"}/> <br />
          {props.showTimeoutMessage ? 
            <Fade in={true} key={"Broken API Text"} {...{ timeout: 1500 }}>
              <Typography>
                Hmm... it looks the photos are having trouble making their way here...<br />
                Try refreshing the page. If it still doesn't work, you may have to come back later :(
              </Typography>    
            </Fade>      
          :
            null
          }
        </div>
      }

    </div>
  )
}

export default PhotoViewer