import { Typography } from "@mui/material"
import styles from "./About.module.css"
import React from "react"


const About = () => {
  return (
    <div className={styles["center-justified"]}>
      <br />
      <img onDragStart={(e) => e.preventDefault()} onContextMenu={(e) => e.preventDefault()} className={styles["center-image"]} alt="It's me!" src="https://gitlab.com/LiefEriccson/photo-portfolio/uploads/cfecd7309f0b51d89e26d86ad81a1d07/silhouette.png" height={"700vh"}></img>
      <br />
      <Typography variant="h5">
        Hello, My name is Yuu.
      </Typography>
      <Typography variant="body1">
        I have always had an interest in photography since I was a child. At the time, the only cameras I could use were very cheap disposable cameras and 
        phone cameras. It wasn't until September, 2022 that I finally purchased my first dedicated camera: <br /> <br />

        <span className={styles.bold}>The Fujifilm XT-4</span> <br /> <br />

        This is still the only camera I own and is the only camera that I need. This camera, paired with the FUJINON XF 56mm f/1.2 R WR Lens and the FUJINON XF23mmF1.4 R LM WR Lens, 
        are the tools that I need to capture candid, special, and fun moments in my daily life! <br /> <br />

        I enjoy taking pictures of a variety of subjects, but I definitely have my biases, such as my cats, stray cats, and flowers! :) <br />
        Whenever I capture moments, I envision a dreamy, nostalgic, and moody kind of vibe. I hope viewing these images also elicits those same emotions.
      </Typography>
      <br />
    </div>
  )
}

export default About
