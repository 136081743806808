import { Typography } from "@mui/material"
import styles from "./MyCode.module.css"
import React from "react"

const MyCode = () => {
  return (
    <div className={styles["center-justified"]}>
      <br />
      <Typography variant="h5">
        Welcome to my Photo Portfolio website!
      </Typography>
      <Typography variant="body1">
        If you are here as a recruiter, hiring manager, or anyone else interested in my site, welcome!! If you stumbled upon here out of curiosity, but are not too keen 
        on code talk, please feel free to navigate to the other sections by clicking on the tabs above!
      </Typography>
      <br />
      <Typography variant="body1">
        I created this site to both showcase my photography and programming skills. This was a fun and simple proof of concept website to see how far
        I could go without depending on a backend. As such, this is a static Single Page Application (SPA) that is hosted through Gitlab Pages. In addition,
        I redirected the standard gitlab pages url to feed into my Google domain name, allowing me to use my current website name instead!
      </Typography>
      <br />
      <Typography variant="h5">
        Technologies Used
      </Typography>
      <Typography variant="body1">
        <ul>
          <li>React</li>
          <ul>
            <li>
              I designed the project structure to follow a typical SPA React project. Each tab is a View component that hosts other components. More details
              will be in the next section.
            </li>
          </ul>
          <li>Material UI </li>
          <ul>
            <li>
              By utilizing MUI's powerful theming and css framework, I was able to create a consistent theme throughout each page. 
            </li>
          </ul>
          <li>Google Photos</li>
          <ul>
            <li>
              Google Photos is where all of the photos are hosted. I was able to hit an endpoint that programmatically pulls out the image URL of the images.
            </li>
            <li>
              It is important to note that this can be buggy depending on Google Photos' traffic, which results in broken links. In the next iteration of this
              site, I will be moving the photos to a more permanent solution, such as AWS or Ruby on Rail's Active Storage.
            </li>
          </ul>
          <li>Google Domains</li>
          <ul>
            <li>
              I already owned a Google Domains name. Gitlab allows one to specify a CNAME and DNS through the settings that will then hook up to Google Domains' 
              custom resource records.
            </li>
          </ul>
          <li>Gitlab</li>
          <ul>
            <li>
              Gitlab Pages is how this static SPA lives on the internet!
            </li>
            <li>
              Additionally, I have configured Gitlab's CI/CD with a .gitlab-ci.yml file that simply builds this project and copies it over to a public
              folder which gets hosted onto Gitlab Pages
            </li>
          </ul>
        </ul>
      </Typography>
      <Typography variant="h5">
        Design Direction
      </Typography>
      <Typography>
        <span className={styles.bold}>Colors</span> <br />
        {`I wanted to create a site that was pleasing on the eyes, so I chose an off-white and off-black color as the background and font. This was set within theme.js and using the <CSSBaseline>
        wrapper for the MainView`} <br /> <br />

        <span className={styles.bold}>Animations</span> <br /> 
        {`In addition to pleasing colors, I also included animations with linear, pleasing timings. It is subtle enough for users to notice but not overbearing. Each animation was done with 
        either plain CSS and CSS keyframing, or using MUI's <Transition> components`}

        <br /> <br /> <img className={styles["center-image"]} src="https://gitlab.com/LiefEriccson/photo-portfolio/uploads/36ed62314845c8f850f77ea3b11ade6d/navbar_animation.gif" alt="Navbar Animation"/>
        <Typography className={`${styles["center-image"]} ${styles["center-justified"]}`} variant="caption">The Navigation Items become more and less opaque</Typography> 
        <br /> <img className={styles["center-image"]} src="https://gitlab.com/LiefEriccson/photo-portfolio/uploads/86ca41682faa6fa56280a0c946ffbc06/go_to_gallery_animation.gif" alt="Go To Gallery Animation"/>
        <Typography className={`${styles["center-image"]}`} variant="caption">The image gallery fades in and subtly grows in size on mouse hover</Typography> 
        <br /> <img className={styles["center-image"]} src="https://gitlab.com/LiefEriccson/photo-portfolio/uploads/a904a006862bc8b0e7028376db5692c6/opening_image_animation.gif" alt="Opening Animation"/>
        <Typography className={`${styles["center-image"]}`} variant="caption">Clicking on the image dims the background and presents itself as a polaroid</Typography>  <br />
        
        <span className={styles.bold}>Photo Layout</span> <br />
        {`Each image belongs to both a <TransitionGroup> component and an <ImageList> component. This allows each image to fade in as it loads.`} <br /> <br />
        
        <span className={styles.bold}>Image Inspection</span> <br />
        {`I wanted to give a brief hint of a retro theming by putting the image into a <Card> component. This would allow me to give it a simple bottom padded border
        to mimic the classic polaroid look. In addition, each image is mapped with a <ClickAwayListener> to close the view when the user clicks anywhere outside the polaroid.`} <br /> <br />
        <span className={styles.bold}>Performance </span><br />
        {`This SPA only fetches the image URLs one time only on page load. After that, the images are cached in the user's browser. The API call is hosted in <MainView>, stored into a state variable,
        and then passed as a prop to <PhotoViewer>. In addition, each image is affixed with Lazy-Loading, so the user's browser will only load images as they come into view when scrolling.
        Most of the components are stateless components as well.`} <br /> <br />

        <span className={styles.bold}>Mobile Compatibility</span>  <br />
        I designed this site in such a way that it will be easy to read and view images on both a desktop computer and mobile phone. This was done by not hard coding any pixel values in CSS.
        Additionally, by using rem, vh/vw, and percentage values, it allowed for consistent and responsive design across multiple screen sizes.
      </Typography>
      <br />
      <Typography variant="h5">
        Closing Thoughts
      </Typography>
      <Typography variant="body1">
        This was a fun and rewarding experience that allowed me to showcase my skills as well as helped spread my love for photography to more people! If you have any questions or suggestions,
        or just want to view the codebase, please feel free to open an Issue on the repository here:  <br /> <br />

        <a href="https://gitlab.com/LiefEriccson/photo-portfolio" target="_blank" rel="noreferrer" style={{color: "inherit"}}>My Photography Portfolio Repository</a> <br /> <br />

        I can also be reached at: <br />
        eric.komachi@gmail.com <br /> <br />
        * All code was 100% done by me. Generative AI was not used.
      </Typography>
      <br />
      <br />
    </div>
  )
}

export default MyCode
