import React from "react"
import styles from "./NavigationPane.module.css"
import { Link } from "react-router-dom";
import { Grid, Fade, Paper, Typography } from "@mui/material";
import InstagramIcon from '@mui/icons-material/Instagram';

const NavigationPane = () => {

  return (
    <Paper>
      <br/>
      <Typography variant="h1" sx={{ textAlign: "center" }}>
        YuuTookAPhoto
        <Fade in={true} style={{ transitionDelay: "1200ms"}} {...{ timeout: 1500 }}>
          <div>
            <a href="https://www.instagram.com/yuutookaphoto/">
              <InstagramIcon className={styles["top-bar-icons"]} />
            </a>
          </div>
        </Fade>
      </Typography>
      <Grid container direction={"row"} justifyContent="space-around" alignItems={"stretch"} id={styles['menu-items']} >
        <Grid item xs={4} className={styles["menu-item"]}>
          <Link to="/" name="/" className={styles["menu-item"]}><Typography variant="h4">Gallery</Typography></Link>
        </Grid>
        <Grid item xs={4} className={styles["menu-item"]}>
          <Link to="/About" name="/About" className={styles["menu-item"]}><Typography variant="h4">About</Typography></Link>
        </Grid>
        <Grid item xs={4} className={styles["menu-item"]}>
          <Link to="/MyCode" name="/MyCode" className={styles["menu-item"]}><Typography variant="h4">My Code</Typography></Link>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default NavigationPane