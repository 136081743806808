import { createTheme } from "@mui/material/styles"

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#766585",
    },
    secondary: {
      main: "#6A9078",
    },
    background: {
      default: "#FFFCF6",
      paper: "#FFFCF6",
    },
    text: {
      primary: "#766585",
    },
    error: {
      main: "#d32f2f",
    },
  },
  typography: {
    fontFamily: "Cormorant Infant",
    fontSize: 12,
    htmlFontSize: 12,
    fontWeightBold: 700,
    h1: {
      fontFamily: "Nothing You Could Do",
      fontSize: "5rem",
    },
    h6: {
      fontFamily: "Nothing You Could Do",
      fontSize: "2rem",
      fontWeight: "bold"
    },
  },
  components: {
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: "#6A9078"
        }
      }
    }
  }
})

theme.typography.body1 = {
  fontFamily: "Cormorant Infant",
  "@media (min-width:600px)": {
    fontSize: "2.2rem",
  },
  [theme.breakpoints.up('md')]: {
    fontSize: "1.5rem",
  }
}
theme.typography.h5 = {
  fontFamily: "Cormorant Infant",
  "@media (min-width:600px)": {
    fontSize: "3rem",
  },
  [theme.breakpoints.up('md')]: {
    fontSize: "2rem",
  }
}
theme.typography.caption = {
  fontFamily: "Cormorant Infant",
  "@media (min-width:600px)": {
    fontSize: "1rem",
  },
  [theme.breakpoints.up('md')]: {
    fontSize: "1rem",
  }
}

export default theme