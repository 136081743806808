import { React, useEffect, useState } from "react"
import { Grid, Container } from "@mui/material"
import { Route, Routes } from "react-router-dom"
import NavigationPane from "src/views/NavigationPane/NavigationPane"
import PhotoViewer from "@components/PhotoViewer/PhotoViewer"
import About from "src/views/About/About"
import MyCode from "src/views/MyCode/MyCode"
import styles from "./MainView.module.css"

const MainView = () => {
  const [photoBucket, setPhotoBucket] = useState([])
  const [showTimeoutMessage, setShowTimeoutMessage] = useState(false)

  useEffect(() => {
    const controller = new AbortController()
    const isCancelled = controller.signal
    fetch(process.env.REACT_APP_API_URL, { isCancelled })
      .then((res) => res.json())
      .then((res) => setPhotoBucket(res.map(url => ({
        original: `${url}=w1024`,
        thumbnail: `${url}=w100`,
      }))))
      .then(() => setTimeout(() => setShowTimeoutMessage(true), 10000))
      .catch(err => {
        if (err.name === "AbortError") {
          console.log("The user has cancelled the request")
        }
      })

    return () => {
      controller.abort()
    }
  }, [])

  return (
    <Grid container direction={"column"} justifyContent="flex-start">
      <Grid item xs={1.5} className={styles.sticky}>
        <NavigationPane/>
      </Grid>
      <Grid item xs={10.5}>
      <Container>
        <Routes>
          <Route path="/" element={<PhotoViewer photoBucket={photoBucket} setPhotoBucket={setPhotoBucket} showTimeoutMessage={showTimeoutMessage}/>} />
          <Route path="/About" element={<About/>} />
          <Route path="/MyCode" element={<MyCode/>} />
        </Routes>
      </Container>
      </Grid>
    </Grid>
  )
}

export default MainView
